import React from "react";
import {Link} from "gatsby";
import {Fade} from 'react-reveal';

import {Container, Row, Col} from 'react-bootstrap'

import LogoWhite from "../../../Elements/Logos/RefLogos/Reflogowhite"
import PhoneWhite from "../../../Elements/Phone/PhoneWhite";
import NavMenuWhite from "../../NavMenu/NavMenuWhite"

export default function TopLightModelative() {
    return(
        <>
        <Container fluid className="top-relative">
            <Row>
                <Col className="logo" xl={2} lg={2} md={3} sm={3} xs={4}>
                    <Fade duration={3000}>
                        <Link to="/"><LogoWhite /></Link>
                    </Fade>
                </Col>
                <Col xl={6} lg={6} md={3} sm={3} xs={2} className="top_empty"></Col>
                <Col className="top-element" xl={2} lg={2} md={4} sm={4} xs={6}>
                    <Fade duration={3000}>
                        <PhoneWhite />
                    </Fade>
                </Col>
                <Col  xl={2} lg={2} md={2} sm={2} xs={2} className="nav-menu-button">
                    <NavMenuWhite />
                </Col>
            </Row>
        </Container>
        </>
    )
}