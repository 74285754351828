import React, {useState} from "react"
import {Button} from "react-bootstrap"


const RefSquaredSelectedBtn = ( { children } ) => {
    let [selected, setSelected]  = useState(false)

  return (
      <>
        <Button variant={`squared ${ selected ? "selectedq" : "" }`} size="xxl" onClick={ () => {
            setSelected(!selected)
        }}>
          { children }
        </Button>
      </>
  )
}

export default RefSquaredSelectedBtn