import React from "react"

// import {Button} from "react-bootstrap"
import RefSquaredSelectedBtn from '../RefStyledButtons/RefSquaredSelectable'
import AllIcon from "../../../Icons/ProjectsIcons/all-projects2.png"

const AllButton = () => {

  return (
      <>
        <RefSquaredSelectedBtn variant="squared" size="xxl" >
          <img src={AllIcon} className="projects-btn-icon" alt="Проекты в категории Хлебные изделия" />
        </RefSquaredSelectedBtn>
      </>
  )
}

export default AllButton