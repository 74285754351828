import React, {Component} from 'react';
import Preloader from "../components/Modules/Preloader"
import Seo from "../components/seo"
import ProjectsPage from "../components/ProjectsPage"


class Projects extends Component {
    state = {
      content: <Preloader text="Загрузка..." />
    };
  
    componentDidMount() {
      setTimeout(
        () =>
          this.setState({
            content: (
              <>
                <Seo 
                    title="🗞Проекты систем холодоснабжения компании.🌡"
                    description="Транскритические системы, аммиачные системы холодоснабжения, 🧑🏼‍🔧собственное холодильное обрудование⚙️. 🌀Холодильная мощность реализованных проектов - более 40МВт."
                    keywords = "проекты, холодоснабжения, транскритика"
                />
                <ProjectsPage />
              </>
            )
          }),
        2000
      );
    }
    render() {
      return this.state.content;
    }
  }
  
export default Projects;