import React, {useState} from 'react'
import {Container, Row, Col} from "react-bootstrap"

// BUTTONS IMPORT 
import MeatButton from "../Elements/Buttons/RefButtons/ProjectsButtons/MeatButton";
import SausageButton from "../Elements/Buttons/RefButtons/ProjectsButtons/SausageButton";
import ChickenButton from "../Elements/Buttons/RefButtons/ProjectsButtons/ChickenButton";
import FishButton from "../Elements/Buttons/RefButtons/ProjectsButtons/FishButton";
import MilkButton from "../Elements/Buttons/RefButtons/ProjectsButtons/MilkButton";
import CheeseButton from "../Elements/Buttons/RefButtons/ProjectsButtons/CheeseButton";
import BreadButton from "../Elements/Buttons/RefButtons/ProjectsButtons/BreadButton";
import ReadyButton from "../Elements/Buttons/RefButtons/ProjectsButtons/ReadyButton";
import VegsButton from "../Elements/Buttons/RefButtons/ProjectsButtons/VegsButton";
// import RetailButton from "../Elements/Buttons/RefButtons/ProjectsButtons/RetailButton";
import PharmaButton from "../Elements/Buttons/RefButtons/ProjectsButtons/PharmaButton";
import HvacButton from "../Elements/Buttons/RefButtons/ProjectsButtons/HvacButton";
import AllButton from "../Elements/Buttons/RefButtons/ProjectsButtons/AllProjButton";


// CATEGORIES IMPORT  
// import RetailCategory from './ProjectsCategories/retail'
import PharmaCategory from './ProjectsCategories/pharmacy'
import VegsFruitsCategory from './ProjectsCategories/vegsfruits'
import MeatCategory from './ProjectsCategories/meatcategory'
import ChickenCategory from './ProjectsCategories/chickencategory'
import FishCategory from './ProjectsCategories/fishcategory'
import MilkCategory from './ProjectsCategories/milkcategory'
import CheeseCategory from './ProjectsCategories/cheesecategory'
import SausageCategory from './ProjectsCategories/sausagecategory'
import BreadCategory from './ProjectsCategories/breadcategory'
import CuisineCategory from './ProjectsCategories/cuisinecategory'
import AirventCategory from './ProjectsCategories/airventcategory'
import AllCategory from './ProjectsCategories/allcategories'



const ProjectsButtons = () => {
    const [category, setCategory] = useState(AllCategory);
    // const [inactive, setActive] = useState("btn-squared-block"); 
    // onClick = {() => setActive("active")}

    function handleClick(category) {
        setCategory(category);
        // setActive("btn-squared-block active");
    }

    return(
        <>
            <Container fluid>
                <Row>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                        <Row style={{paddingBottom: "3vh", paddingLeft: "3vw", paddingTop: "0",}}>
                            <Col className="btn-squared-block" onClick={()=> handleClick(MeatCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <MeatButton  />
                                <p>Мясная</p><p>промышленность</p>
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(SausageCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <SausageButton />
                                <p>Колбасные</p><p> изделия</p>
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(ChickenCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <ChickenButton />
                                <p>Птицепереработка</p>
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(FishCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <FishButton />
                                <p>Рыбопереработка</p>
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(MilkCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <MilkButton />
                                <p>Молочная</p><p>промышленность</p>
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(CheeseCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <CheeseButton />
                                <p>Сырные</p><p>производства</p>
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(BreadCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <BreadButton />
                                <p>Хлебные и</p><p>кондитерские</p> <p>производства</p>
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(CuisineCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <ReadyButton />
                                <p>Готовые блюда</p>
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(VegsFruitsCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <VegsButton />
                                <p>Овощи и</p><p>фрукты</p>
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(PharmaCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <PharmaButton />
                                <p>Фармацевтика</p>
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(AirventCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <HvacButton />
                                <p>Вентиляция и</p><p>кондиционирование</p>
                            </Col>
                            <Col className="btn-squared-block" onClick={()=> handleClick(AllCategory)} xl={3} lg={3} md={3} sm={3} xs={4}>
                                <AllButton />
                                <p>Все проекты</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1}  xs={1}></Col>
                    <Col xl={5} lg={12} md={12} sm={12} xs={12} className="ref-allprojects-list">
                        {category}
                    </Col>
                </Row>
            </Container>    
        </>
        
    )
}

export default ProjectsButtons