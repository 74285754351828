import React from "react"

import {Button} from "react-bootstrap"
import HvacIcon from "../../../Icons/ProjectsIcons/hvac-refeng2.png"

const HvacButton = () => (

  <>
    <Button variant="squared" size="xxl">
      <img src={HvacIcon} className="projects-btn-icon" alt="Проекты в категории Хлебные изделия" />
    </Button>
  </>
)

export default HvacButton