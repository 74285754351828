import React from "react"

import {Button} from "react-bootstrap"
import MeatIcon from "../../../Icons/ProjectsIcons/steak-refeng.png"

const MeatButton = () => (

  <>
    <Button variant="squared" size="xxl" name="meat">
      <img src={MeatIcon} className="projects-btn-icon" alt="Проекты в категории Хлебные изделия" />
    </Button>
  </>
)

export default MeatButton