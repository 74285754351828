import React from "react"

// import {Button} from "react-bootstrap"
import RefSquaredSelectedBtn from '../RefStyledButtons/RefSquaredSelectable'
import BreadIcon from "../../../Icons/ProjectsIcons/bread-refeng.png"

const BreadButton = () => (

  <>
    <RefSquaredSelectedBtn variant="squared" size="xxl">
      <img src={BreadIcon} className="projects-btn-icon" alt="Проекты в категории Хлебные изделия" />
    </RefSquaredSelectedBtn>
  </>
)

export default BreadButton