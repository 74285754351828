import React from "react"

import {Button} from "react-bootstrap"
import CheeseIcon from "../../../Icons/ProjectsIcons/cheese-refeng.png"

const CheeseButton = () => (

  <>
    <Button variant="squared" size="xxl">
      <img src={CheeseIcon} className="projects-btn-icon" alt="Проекты в категории Хлебные изделия" />
    </Button>
  </>
)

export default CheeseButton