import React from "react"

import {Button} from "react-bootstrap"
import PharmaIcon from "../../../Icons/ProjectsIcons/pharmacy.png"

const PharmaButton = () => (

  <>
    <Button variant="squared" size="xxl">
      <img src={PharmaIcon} className="projects-btn-icon" alt="Проекты в категории Хлебные изделия" />
    </Button>
  </>
)

export default PharmaButton