import React from 'react'
import {Container, Row, Col} from "react-bootstrap"

import TopLightRelative from "../Modules/Top/TopLightRelative"
import ProjectsButtons from "./ProjectsButtons"
import FooterPage from "../Modules/FooterPage"


const ProjectsPage = () => {
    return(
        <Container fluid className="projects-container">
            <div className="projects-overlay"></div>
            <Row>
                <Col>
                    <TopLightRelative />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>РЕАЛИЗОВАННЫЕ ПРОЕКТЫ</h1>
                </Col>
            </Row>
            <Row>
                <Col className="project-buttons-block">
                    <ProjectsButtons />
                </Col>
            </Row>
            <Row>
                <Col style={{padding: '0'}} >
                    <FooterPage />
                </Col>
            </Row>

        </Container>
        
    )
}

export default ProjectsPage